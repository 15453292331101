<template>
  <div class="app-header fixed-top">
    <div class="app-header-column left">
      <div class="app-logo mobile-hidden">
        <a class="dropbtn" href="/">
          <img src="@/assets/images/logo.png" alt="systems alliance europe" />
        </a>
      </div>

      <div class="app-title">
        <h3>SAE Collaboration App</h3>
      </div>
    </div>
    <div class="app-header-column right">
      <div class="app-header-switch-portal mobile-hidden">
        <md-menu class="switch-menu-trigger" md-align-trigger>
          <md-button md-menu-trigger class="header-link">
            <img src="@/assets/images/icons/icon-settings.png" class="setting-icon" />
            {{ "Switch Portal" | translate("60298.LongText") }}
            <img
              v-if="showDropdown"
              src="@/assets/images/icons/icon-dropdown.png"
            />
          </md-button>
          <md-menu-content v-if="showDropdown">
            <md-menu-item
              v-for="(app, index) in applications"
              :key="index"
              :href="app.Urls[0].Url"
              >{{ app.Urls[0].ApplicationName }}</md-menu-item
            >
          </md-menu-content>
        </md-menu>
      </div>

      <div class="app-header-user popover_container mobile-hidden">
        <div class="dropdown-info">
          <div class="d-flex align-items-center">
            <div>
            <img src="@/assets/images/icons/icon-user.png" class="icon-user"/>
            </div>
            <div class="user-name">{{ userName }}</div>
          </div>
          <div class="dropdown-content">
            <div class="dropdown_li">
              <span>{{ "User Role" | translate("60297.ShortText") }} : </span>
              <span id="userRole">{{ userRoleText }}</span>
            </div>
            <div class="dropdown_li">
              <span>{{ "ID" | translate("60142.ShortText") }} : </span>
              <span id="email">{{ userID }}</span>
            </div>
            <div class="dropdown_li">
              <span>{{ "Tenant" | translate("60143.ShortText") }} : </span>
              <span id="tenant">{{ tenant }}</span>
            </div>
            <div class="dropdown_li">
              <span>{{ "API Version" | translate("60144.ShortText") }} : </span>
              <span id="apiversion">{{ apiVersion }}</span>
            </div>
            <div class="dropdown_li">
              <span>{{ "WebApp Ver." | translate("60145.ShortText") }} : </span>
              <span id="appversion">{{ appVersion }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="app-header-controls">
        <md-menu class="select-language" md-align-trigger>
          <md-button md-menu-trigger class="header-link" data-cy=lang>
            {{ $i18n.locale | uppercase }}
            <img src="@/assets/images/icons/icon-dropdown.png" class="down-arrow-icon"/>
          </md-button>
          <md-menu-content>
            <md-menu-item
              v-for="(lang, index) in languages"
              :key="index"
              @click="switchLanguage(lang.LangText, lang.SysValueNo, lang)"
              >{{ lang.LangText | uppercase }}</md-menu-item
            >
          </md-menu-content>
        </md-menu>
        <div class="app-logout">
          <a class="header-link" @click="logOut()">{{
            "Log Out" | translate("60173.LongText")
          }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadApplications, logOut } from "@/services/service-methods";
import {
  languageTranslationService,
  branchChangeService,
  loaderService,
  BranchorDepartmentChangeService
} from "@/services/observables";
import {
  getAllAvailableCreatedBranch,
  getAllAvailableDynamicDropDowns,
  getBranchAndDepartment,
  setBranchAndDepartment,
} from "@/services/collaboration.service";
import Vue from "vue";
import { addTrace } from "@/services/logging";

export default {
  name: "Header",
  data: () => ({
    userRoleText: String,
    userID: String,
    tenant: String,
    apiVersion: String,
    appVersion: String,
    applications: [],
    showDropdown: false,
    createdBranchId: null,
    createdDepartmentName: null,
    branchNo: 0,
    deptNo: 0,
    createdBranches: [],
    departments: [],
    isBranchthere: [],
    branchTooltip: "",
    branchCountrytip: "",
    value: false, 
    languages:[],
    onOffNotification:true,
    isBrowserNotificationEnable:true,
    isEmailNotificationEnable:true,
    arrDepartment:[],
    columns:[],
  }),

  // on created hook
  created() {
    //  get all data from sessionstorage and load application according language
    this.userRoleText = sessionStorage.getItem("userRoleText");
    this.userID = sessionStorage.getItem("emailAddress");
    this.tenant = sessionStorage.getItem("tenant");
    this.apiVersion = sessionStorage.getItem("apiVersion");
    this.appVersion = sessionStorage.getItem("appVersion");
    this.loadApplicationsLangWise();
    this.getAllDropDownValues();
   
  },
  mounted() {
    // get langauge and set to variable
    this.$i18n.locale = this.lang || this.$i18n.locale;
  },
  computed: {
    // set updated user name on sessionstorage
    userName: function () {
      return sessionStorage.getItem("fullname");
    },

    // set updated lang code on sessionstorage
    langCode: function () {
      return sessionStorage.langCode;
    },

    // set updated language on cookie
    lang: function () {
      return this.$cookie.get("setLanguages");
    },
  },
  methods: {
    // get translation language 
    getTranslatedLanguage:function(value,text){
      return this.translationCode[this.$i18n.locale][value]==undefined?this.translationCode['en'][value][text]: this.translationCode[this.$i18n.locale][value][text]==undefined?this.translationCode['en'][value][text]:this.translationCode[this.$i18n.locale][value][text]
    },

    // get branch and department and set data
    setBranchAndDepartmentValue: async function (callback) {
      await getBranchAndDepartment((res) => {
        if (res && res.status == 200) {
          this.onOffNotification= res.data.isNotificationEnable
          this.isBrowserNotificationEnable=res.data.isBrowserNotificationEnable
          this.isEmailNotificationEnable=res.data.isEmailNotificationEnable
          this.arrDepartment=res.data.arrDepartment
          this.arrEntryStatus=res.data.arrEntryStatus;
          this.arrCollaborationEntryBranch=res.data.arrCollaborationEntryBranch;
          this.translateLanguageCode=(res.data.translationLanguage==null || res.data.translationLanguage=='' ||res.data.translationLanguage==undefined )?'':res.data.translationLanguage
          this.languagevalue=res.data.languageCode==null ||res.data.languageCode==''||res.data.languageCode==undefined ?'en-UK':res.data.languageCode
          this.columns = res.data.arrSelectedColumn;
          if(this.languages!=null){
          let setLang = this.languages.find(
            (langs) => langs.SysValueId == this.languagevalue
          );
            this.$i18n.locale=setLang.LangText
          }else{
             this.$i18n.locale=this.languagevalue.split('-')[0]
          }
          let branch = this.createdBranches.find(
            (branch) => branch.entityNo == res.data.branchNo
          );
          if (branch) {
            this.setBranchData(branch);
            branchChangeService.setBranch(this.createdBranchId);
          }
          if (res.data.branchNo == 0) {
            this.switchBranch(this.createdBranches[0]);
          }
          if (callback) {
            var obj = {
              branch: this.branchNo,
            };
            return callback(obj);
          }
        }else{
          this.switchBranch(this.createdBranches[0]);
        }
      }).catch((err) => {
        Vue.notify({
          group: "foo",
          type: "error",
          title: `${err}`,
          duration: 4000,
        });
        addTrace(err, 4);
      });
    },

    // switch language and load application
    switchLanguage: function (lang, langCode, langObj) {
      this.$i18n.locale = lang;
      sessionStorage.setItem("lang", lang);
      this.$cookie.set("setLanguages", lang);
      this.$cookie.set("localeLang", langObj.SysValueId);
      this.$cookie.set("localeLangCode", langCode);
      sessionStorage.setItem("langCode", langCode);
      sessionStorage.setItem("localeLang", langObj.SysValueId);
      languageTranslationService.setLang(sessionStorage.getItem("lang"));
      let branchNo = this.branchNo;
      let self=this;
      self.setBranchAndDepartmentValue(function (resBranchDepart) {
        if(resBranchDepart){
          self.setData(branchNo)
          self.loadApplicationsLangWise();
          self.getAllDropDownValues();
        }
      })
    },

    // load application according language
    loadApplicationsLangWise: function () {
      this.applications=[];
      //get translation code
      const texts = {};
      this.languages = JSON.parse(sessionStorage.getItem("languages"));
      if ( this.languages) {
         this.languages.map((language) => {
          texts[language.LangText] = JSON.parse(
            sessionStorage.getItem(language.LangText + "-localeTexts")
          );
        });
      }
      this.translationCode = texts;
      // get applications list from loadApplications api
      loadApplications().then((res)=>
      {
        if(res.status == 200){
          res.data.forEach((app)=>{
            let obj={
              ApplicationNo:app.ApplicationNo,
              Urls:app.Urls.filter(
                (url) => url.LanguageId == sessionStorage.getItem("langCode")),
            }
            this.applications.push(obj)
          })
          this.showDropdown = true;
        }
      })
      .catch((err) => {
        Vue.notify({
          group: "foo",
          type: "error",
          title: `${err}`,
          duration: 4000,
        });
        addTrace(err, 4);
      });
    },

    // get all drop down values
    getAllDropDownValues: function () {
      this.createdBranches = [];
      this.departments = [];

      let self = this;
        this.getAllAvailableCreatedBranches(function (resBranch) {
          if (resBranch) {
            self.getAllAvailableDepartments(function (resDepartment) {
              if (resDepartment) {
                self.setBranchAndDepartmentValue() 
                
              }
            });
          }
        });
      
    },

    // get avilable branches
    getAllAvailableCreatedBranches: function (callback) {
      this.createdBranches = [];
      getAllAvailableCreatedBranch()
        .then((res) => {
          if (res.status == 200) {
            res?.data.forEach((element) => {
              if (element?.entityType == "10019") {
                let obj = {
                  text:
                    element.shortName +
                    (element.country ? " " + element.country : "") +
                    (element.city ? " - " + element.city : ""),
                  value: element.shortName,
                  nametooltip: element.longName,
                  countrytip:
                    (element.country ? " " + element.country : "") +
                    (element.zipCode ? " - " + element.zipCode : "") +
                    (element.city ? "  " + element.city : ""),
                  entityNo: element.entityNo,
                };
                this.createdBranches.push(obj);
              }
            });
            if (callback) {
              return callback(true);
            }
          }else{
            if (callback) {
              return callback(false);
            }
          }
        })
        .catch((err) => {
          console.log("getbranch:",err)
          Vue.notify({
            group: "foo",
            type: "error",
            title: `${err}`,
            duration: 4000,
          });
          addTrace(err, 4);
          if (callback) return callback(false);
        });
    },

    // get avilable department
    getAllAvailableDepartments: function (callback) {
      getAllAvailableDynamicDropDowns("Department")
        .then((res) => {
          if (res.status == 200) {
            res?.data.forEach((element) => {
              let obj = {
                text: "",
                value: "",
                deptNo: element.SysValueNo,
              };
              element.TextValues.forEach((ele) => {
                (obj.text = ele.LongText ? ele.LongText : ele.ShortText),
                  (obj.value = element.SysValueId),
                  this.departments.push(obj);
              });
            });
            if (callback) {
              return callback(true);
            }
          }else{
            if (callback) {
              return callback(false);
            }
          }
        })
        .catch((err) => {
          console.log("getdept:",err)
          Vue.notify({
            group: "foo",
            type: "error",
            title: `${err}`,
            duration: 4000,
          });
          addTrace(err, 4);
          if (callback) return callback(false);
        });
    },

    // chnage branch and set data
    switchBranch: function (branch) {
      let self=this;
          self.setBranchData(branch);
          let branchNo = branch.entityNo;
          self.arrCollaborationEntryBranch=[]
          self.setData(branchNo, function (response) {
              if (response) {
                branchChangeService.setBranch(branch.value);
                BranchorDepartmentChangeService.setBranchorDepartment({type:"branch",value:branch.value})
              }
            });
    },
    
    //set branch data
    setBranchData(branch){
      this.createdBranchId = branch.value;
      this.branchNo = branch.entityNo;
      this.branchTooltip = branch.nametooltip;
      this.branchCountrytip = branch.countrytip;
    },

    //set api data
    setData(branchNo, callback) {
      let obj = {
        isNotificationEnable:this.onOffNotification,
        arrEntryStatus:this.arrEntryStatus,
        isBrowserNotificationEnable:this.isBrowserNotificationEnable,
        isEmailNotificationEnable:this.isEmailNotificationEnable,
        arrCollaborationEntryBranch:this.arrCollaborationEntryBranch,
        branchNo: branchNo,
        arrDepartment:this.arrDepartment,
        languageCode: sessionStorage.getItem("localeLang"),
        translationLanguage:this.translateLanguageCode,
        arrSelectedColumn:this.columns
      };
       loaderService.setLoader(true);
      setBranchAndDepartment(obj, function (res) {
        if (res && res.status == 200) {
          if (callback) {
            loaderService.setLoader(false);
            return callback(true);
          }else{
            loaderService.setLoader(false);
          }
        }else{
           if (callback) {
            loaderService.setLoader(false);
            return callback(false);
          }else{
            loaderService.setLoader(false);
          }
        }
      });
    },
    // logout method coming from services
    logOut,
  },
};
</script>

<style lang="scss" scoped>
.app-header {
  background-color: #ffa200;
  border-color: #ffc107;
  height: 55px;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: space-between;

  padding: 0 16px;

  .app-header-column {
    display: flex;
    align-items: center;

    > div {
      margin-right: 8px;
    }

    &.right {
      > div {
        display: flex;
        align-items: center;

        &.app-header-switch-portal {
          img {
            margin: 0 8px;
          }

          .switch-menu-trigger {
            cursor: pointer;
          }
        }

        &.app-header-user {
          img {
            margin: 0 8px;
          }
        }

        &.app-header-controls {
          > div {
            margin-left: 8px;
          }

          .select-language {
            margin-right: 20px;
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;

            img {
              margin-left: 8px;
            }
          }
        }

        .header-link {
          color: #fff;
          text-transform: unset;
        }

        a.dropdown-item {
          cursor: pointer;
        }
      }
    }

    > .app-logo {
      img {
        height: 55px;
        width: auto;
      }
    }
  }
}

.popover_container {
  .dropdown-info {
    margin-left: 3px;
    position: relative;
    display: inline-block;
  }
}

.popover_container .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f6febd;
  min-width: 315px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 15px;
  margin-top: 20px;
  color: #000;
}

.popover_container .dropdown-info:hover .dropdown-content {
  display: block;
}

.dropdown_li span:first-child {
  display: inline-block;
  text-align: left;
  font-weight: bold;
  font-size: 12px;
  float: left;
  margin-right: 10px;
}

.dropdown_li span:nth-child(2) {
  font-size: 12px;
  float: left;
  display: inline-block;
}

.dropdown_li {
  margin-bottom: 5px;
  display: block;
  float: left;
  width: 100%;
}

.dropdown-content::after {
  position: absolute;
  content: "";
  top: -15px;
  left: 20px;
  border-bottom: 15px solid #f6febd;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
}

.dropdown-content::before {
  position: absolute;
  content: "";
  top: -15px;
  left: 20px;
  border-bottom: 15px solid #afafaf;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
}

.addUnderLine:hover {
  text-decoration: underline;
  cursor: pointer;
}

.fullwidth {
  width: 100%;
  max-width: 100% !important;
}

@media (min-width: 1501px) {
  .proportionalMargin {
    margin-right: -webkit-calc(100% - 505px);
    margin-right: -moz-calc(100% - 505px);
    margin-right: calc(100% - 505px);
  }
  .app-title h3 {
        margin-bottom: 0;
  }
}

@media (max-width: 1500px) {
.dropbtn img{
    height: 40px !important;
  }
  .app-title h3 {
        font-size: 1.2rem;
        margin-bottom: 0;
  }
  .select-language .header-link{
    min-width: 1px !important;
  }
}

 @media screen and (max-width: 1200px) {
   .dropbtn img{
    height: 30px !important;
  }
  .app-title h3 {
        font-size: 1rem;
        margin-bottom: 0;
  }
  .setting-icon,.icon-user,.message_plus,.message_minus,.notification_on,.notification_off{
    height: 14px;
  }
  .header-link,.user-name{
    font-size: 12px;
  }
  .down-arrow-icon {
     height: 8px;
  }
}

@media (min-width: 992px) {
  .proportionalMargin {
    margin-right: 960px;
    margin-right: -webkit-calc(100% - 465px);
    margin-right: -moz-calc(100% - 465px);
    margin-right: calc(100% - 465px);
  }
  .header-link .text-wrap-inner {
    max-width: 140px !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (min-width: 768px) {
  .proportionalMargin {
    margin-right: 720px;
    margin-right: -webkit-calc(100% - 345px);
    margin-right: -moz-calc(100% - 345px);
    margin-right: calc(100% - 345px);
  }
}

@media (min-width: 576px) {
  .proportionalMargin {
    margin-right: -webkit-calc(100% - 255px) / 2;
    margin-right: -moz-calc(100% - 255px) / 2;
    margin-right: calc(100% - 255px) / 2;
  }
  .dropbtn img{
    height: 24px !important;
  }
  .app-title h3 {
        font-size: 0.8rem;
        margin-bottom: 0;
        margin-left: 7px;
  }
  .setting-icon,.icon-user,.message_plus,.message_minus,.notification_on,.notification_off{
    height: 12px;
    font-size: 18px !important;
  }
  .message_plus,.message_minus {
    margin-left: 5px;
  }
  .notification_on,.notification_off {
    margin-left: 15px;
  }
  .header-link,.user-name{
    font-size: 13px;
  }
  .down-arrow-icon {
     height: 6px;
  }
  .app-header .app-header-column > div {
    margin-right: 0px !important;
  }
  .app-header .app-header-column.right > div.app-header-controls .select-language {
    margin-right: 0px;
    margin-left:0px;
  }
  .header-link .text-wrap-inner {
        max-width: 70px ;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
