<template>
  <div id="app">
    <div>
      <vue-loaders-ball-beat
        v-if="showLoader"
        color="black"
      ></vue-loaders-ball-beat>
      <v-app>
        <Header v-if="!$route.params.token" />
        <div
          class="main-content"
          v-bind:class="{ plugin: $route.params.token ? true : false }"
        >
          <div class="center-content-wrapper">
            <!-- <SidePanel /> -->
            <div class="container-fluid">
              <router-view />
            </div>
          </div>
        </div>
        <notifications group="foo" />
        <footer class="fixed-bottom" v-if="!$route.params.token">
          <div class="container">
            <div class="row">
              <div id="footernav" class="footernav col-sm-6 col-12">
                <ul class="footmenu">
                  <li>
                    <a
                      :href="'IMPRINT' | translate('60014.LongText')"
                      target="_blank"
                      >{{ "Imprint" | translate("60014.ShortText") }}</a
                    >
                  </li>
                  <li>
                    <a
                      :href="'DISCLAIMER' | translate('60016.LongText')"
                      target="_blank"
                      >{{ "Data privacy" | translate("60016.ShortText") }}</a
                    >
                  </li>
                </ul>
              </div>
              <div class="col-sm-6 col-12">
                <div id="copyright" class="text-right copyright">
                  {{ "System Alliance Europe eG" | translate("60017.LongText") }}
                </div>
              </div>
            </div>
          </div>
        </footer>
      </v-app>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import { versionApi } from "@/services/service-methods";
// import SidePanel from "@/components/SidePanel.vue";
import { loaderService } from "@/services/observables";

const app = document.createElement("div");
app.setAttribute("data-app", true);
document.body.append(app);

export default {
  name: "App",
  data() {
    return {
      showLoader: false,
    };
  },
  components: {
    Header,
    // SidePanel,
  },
  created() {
    // get version data and validate it
    versionApi(function (valid) {
      if (valid == true) {
        import("./components/Header.vue").then(() => {});
      }
    });
    // set loader 
    loaderService.getLoader().subscribe((res) => {
      this.showLoader = res;
    });
  },
  mounted() {
    // set languages to cookie
    let checkCookieLang = this.$cookie.get("setLanguages");
    if (!checkCookieLang) {
      let browserLang = navigator.language.split("-")[0];
      this.$cookie.set("setLanguages", browserLang);
    }
  },
};
</script>

<style lang="scss" scoped>
#app {
  height: 100vh;
  overflow: hidden;
}
.main-content {
  height: calc(100vh - 94px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-top: 55px;
  background-color: #eee !important;
  padding: 15px 1vh;
  .body-container {
    flex: 1;
    margin-top: 35px;
    margin-bottom: 40px;
  }
}

@media screen and(max-width: 992px) {
  .main-content {
    padding: 15px 0vh;
  }
}

.fixed-bottom {
  background: #ffffff;
}

.footernav {
  max-height: 50px;
}

.footernav ul li a {
  display: block;
  padding: 0px 15px;
  color: #605a5d;
}

.footernav ul li {
  float: left;
  -webkit-box-shadow: inset 2px 0 5px -4px #aaaaaa;
  -moz-box-shadow: inset 2px 0 5px -4px #aaaaaa;
  box-shadow: inset 2px 0 5px -4px #aaaaaa;
  margin-left: 1px;
  list-style-type: none;
}

.text-right {
  text-align: right;
}
.center-content-wrapper {
  display: flex;
  align-items: flex-start;
  height: calc(100vh - 95px);
}
.v-sheet.v-card {
  border-radius: 8px !important;
}

.vue-loaders {
  position: absolute;
  z-index: 99999;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0000001f;
}
.plugin {
  padding: 0px 0vh;
}
</style>
